import config from '../../data/SiteConfig'
import { HOUR_FROM_TIME_OF_DAY } from '../configs/time.config'
import { format, parseISO } from 'date-fns'

function formatDate(date, dateFormat = config.dateFormat) {
  const parsedDate = parseISO(date)
  return format(parsedDate, dateFormat)
}

function getCurrentHours() {
  return new Date().getHours()
}

function isDayTime() {
  const hourOfTheDay = getCurrentHours()
  // 7am to 6:59pm
  return hourOfTheDay > HOUR_FROM_TIME_OF_DAY.morning && hourOfTheDay < HOUR_FROM_TIME_OF_DAY.night
}

/**
 * Sanitizes a URL to prevent XSS attacks
 * Only allows http, https, and relative URLs
 * @param {string} url - The URL to sanitize
 * @returns {string} The sanitized URL or a default URL
 */
function sanitizeUrl(url) {
  if (!url) return config.siteUrl
  
  try {
    // Try to parse the URL to ensure it's valid
    const parsedUrl = new URL(url, config.siteUrl)
    
    // Only allow http and https protocols
    if (parsedUrl.protocol !== 'http:' && parsedUrl.protocol !== 'https:') {
      return config.siteUrl
    }
    
    return parsedUrl.toString()
  } catch (e) {
    // If URL parsing fails, check if it's a relative URL
    if (url.startsWith('/')) {
      return url
    }
    return config.siteUrl
  }
}

export { formatDate, isDayTime, sanitizeUrl }
