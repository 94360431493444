/* eslint-disable import/no-extraneous-dependencies */
import { createRoot } from 'react-dom/client'
import { loadableReady } from '@loadable/component'

import { WrapRootElement } from './src/wrap-root-element'

export const wrapRootElement = WrapRootElement

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    const root = createRoot(container)
    root.render(element)
    if (callback) callback()
  })
}

export const onInitialClientRender = () => {
  // eslint-disable-next-line no-return-assign
  document.getElementById('___loader').style.display = 'none'
  // eslint-disable-next-line no-console
  console.log(
    "%c Welcome! you're in ashmeetsehgal.com console, in case you want to collaborate contact",
    'background: #5183f5; color: #f2f2f2; font-size: 24px;',
  )
  document.addEventListener('copy', (event) => {
    // Only add source URL when current page is in the content section
    if (document.location.href.includes('/content/')) {
      const pagelink = `\n\nRead more at: ${document.location.href} \n\n`
      event.clipboardData.setData('text', document.getSelection() + pagelink)
      event.preventDefault()
    }
  })

  window.addEventListener(
    'contextmenu',
    (e) => {
      e.preventDefault()
    },
    false,
  )

  const el = document.getElementById('g-recaptcha-response')
  if (el) {
    el.setAttribute('required', 'required')
  }
}

// export const onClientEntry = () => {
//   if (process.env.NODE_ENV === 'development') {
//     // eslint-disable-next-line global-require
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//       trackAllPureComponents: true
//     })
//   }
// }
